import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Box, Button, Dialog, DialogTitle, Typography, IconButton, DialogContent } from "@mui/material";
import { t } from "i18next";
import api from "../API/Fetch_data_Api";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";


const CheckoutForm = ({ amount, order_id }) => {

    const stripe = useStripe();
    const elements = useElements({ theme: "night" });

    const theme = useTheme();
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (result.error) {
            toast.error(result.error.message);
        } else {

            api
                .add_transactions(order_id, "success")
                .then(response => {
                    toast.success("Service Booked successfully");
                    localStorage.removeItem("cart");
                    localStorage.removeItem("CartDetails");
                    localStorage.removeItem("cartItems");
                    localStorage.removeItem("orderNote");
                }).then(res => {
                    navigate("/")
                    window.location.reload();
                });
        }

    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <Box sx={{ color: "white" }}>
                    <CardElement className="mt20 mb20" />
                </Box>

                <Button
                    type="submit"
                    disabled={!stripe || !elements}
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2, borderRadius: '8px', backgroundColor: theme.palette.background.buttonColor }}

                >
                    {t("make_a_payment")}
                </Button>
            </form>
        </>
    );
};

function Stripe({ amount }) {
    const key = localStorage.getItem('stripeKey');
    const stripekey = key;


    const stripePromise = loadStripe(stripekey);

    const options = {
        // passing the client secret obtained from the server
        appearance: {
            theme: 'flat',
        }
    };

    const [open, setOpen] = useState(false);
    const [orderID, setOrderID] = useState(0);

    const handleOpen = () => {
        setOpen(true)

        const date = localStorage.getItem("selectedDate");
        const time = localStorage.getItem("selectedSlot");

        const address_id = localStorage.getItem("selectedAddres");
        const DeliveryMethod = localStorage.getItem("Home");
        const address = JSON.parse(address_id);

        let order_note = localStorage.getItem("orderNote");

        api.plaecOrder("stripe", "awaiting", "", date, time, DeliveryMethod, address.id, order_note)
            .then((response) => response)
            .then((result) => {
                if (result.error === false) {
                    setOrderID(result.data.order_id);
                    localStorage.removeItem("cart");
                    localStorage.removeItem("CartDetails");
                    localStorage.removeItem("cartItems");
                    localStorage.removeItem("orderNote");
                }
            })
            .catch((error) => console.log("error", error));
    }

    const handleClose = () => {
        setOpen(false)
        api
            .add_transactions(orderID, "cancelled")
            .then((response) => { });
    }


    return (
        <>
            <Button variant="outlined" fullWidth sx={{ my: 3 }} onClick={e => handleOpen()}>
                {t("make_a_payment")}
            </Button>

            <Dialog open={open} maxWidth={"lg"}>
                <Box width={{ xs: "100%", md: 500 }} height={250}>
                    <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="h6" > Pay with stripe </Typography>
                        <IconButton aria-label="handle-close" onClick={e => handleClose()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box my={5} sx={{ color: "white " }}>
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm amount={amount} order_id={orderID} />
                            </Elements>
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        </>

    );
}

export default Stripe;
