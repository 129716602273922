import { BorderColorTwoTone, CloseRounded } from "@mui/icons-material";
import {
    Backdrop,
    Box,
    Button,
    Chip,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Radio,
    Typography,
    Dialog
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import AddressForm, { UpdateAddress } from "./AddressForm";
import { API_URL } from "../../../config/config";
import UpdateDrawer from "../../../Drawers/UpdateDrawer";
import api from "../../../API/Fetch_data_Api";
import UpdateMapSide from "../../../Drawers/UpdateMapSide";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../../../redux/UserAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";


const Address = ({ onSelectAddress }) => {
    return (
        <div>
            <Box>
                <DynamicAddress onSelectAddress={onSelectAddress} />
            </Box>
        </div>
    );
};

export const AddAddress = () => {
    const [openAdd, isOpenAdd] = useState(false);

    const handleOpenAddress = () => {
        isOpenAdd(true);
    };

    const handleCloseAdderss = () => {
        isOpenAdd(false);
    };

    const theme = useTheme();

    return (
        <>
            <Box mx={2}>
                <Button
                    variant="outlined"
                    className="mt-3-h-100"
                    fullWidth
                    onClick={handleOpenAddress}
                >
                    +{t("add_address")}
                </Button>

                <Dialog open={openAdd}
                    sx={{
                        zIndex: 1101,
                        overflow: "auto",
                        width: "100%",
                    }}
                    maxWidth={"lg"}

                >
                    <Box
                        sx={{ background: theme.palette.background.box }}
                        borderRadius={"10px"}
                        padding={1}
                    >
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography variant="h6">{t("add_address")}</Typography>
                            <IconButton className="mt-m1" onClick={handleCloseAdderss}>
                                <CloseRounded />
                            </IconButton>
                        </Box>
                        <Divider />

                        <AddressForm />
                    </Box>
                </Dialog>
            </Box>
        </>
    );
};

export default Address;

// --------------------Dynamic Address-----------------------
export const DynamicAddress = ({ onSelectAddress }) => {
    const [checked, setchecked] = useState();
    const [addressSelected, setAddressSelected] = useState();
    const [editIndex, setEditIndex] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const isAddressInDrawer = false;

    // to handle drawer update
    const [openDrawer, setOpenDrawer] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const dispatch = useDispatch();
    const addresses = useSelector((state) => state.UserAddress)?.address;
    const [address, setLocalAddress] = useState(addresses);

    const setDefaultAddress = (address) => {
        address.forEach((add, index) => {
            if (add.is_default === "1") {
                setAddressSelected(add);
                setchecked(index);
                // Call the onSelectAddress function to update the parent component
                onSelectAddress(add);
                const selectedAddress = JSON.stringify(add);
                localStorage.setItem("selectedAddres", selectedAddress);
            }
        })
    }
    useEffect(() => {
        // if (addresses.length === 0) {
        //     api
        //         .getAddress()
        //         .then((response) => {
        //             setLocalAddress(response.data);
        //             setDefaultAddress(response.data);
        //             dispatch(setAddress(response.data));
        //         })
        //         .catch((error) => console.log("error", error));
        // } else {
        // }
        setDefaultAddress(address);

        setLocalAddress(address);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const handleDelete = (index) => {
        setDeleteIndex(index);
    };

    const handleDeleteClose = () => {
        setDeleteIndex(null);
    };

    const handleSelect = (index, address) => {
        setAddressSelected(address);
        setchecked(index);

        // Call the onSelectAddress function to update the parent component
        onSelectAddress(address);
        const selectedAddress = JSON.stringify(address);
        localStorage.setItem("selectedAddres", selectedAddress);
    };

    // Use useEffect to watch for changes in addressSelected and then update localStorage
    useEffect(() => {
        if (addressSelected !== undefined) {
            localStorage.setItem("selectedAddres", JSON.stringify(addressSelected));
            localStorage.setItem("checked", checked);
        }
    }, [addressSelected, checked]);

    const handleEdit = (index, address) => {
        setEditIndex(index);;
    };

    const handleEditClose = () => {
        setEditIndex(null);
    };

    // to delete particular address
    const handleDeleteAddress = (address_id) => {
        api
            .DeleteAddress(address_id)
            .then((result) => {
                toast.success(result.message);

                api.getAddress()
                    .then((response) => {
                        dispatch(setAddress(response.data));
                    })
                    .catch((error) => console.log("error", error));
            })
            .catch((error) => console.log("error", error));
        handleDeleteClose();
    };

    // to update particular address
    const handleUpdate = (address_id) => {
        const myHeaders = new Headers();
        const token = localStorage.getItem("Token");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formData = new FormData();
        formData.append("address_id", address_id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };

        fetch(`${API_URL}/add_address`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                toast.success(result.message);
            })
            .catch((error) => console.log("error", error));
    };

    const theme = useTheme();



    function handleEditDrawer() {
        setOpenDrawer(!openDrawer);
        localStorage.setItem("EditableAddress", JSON.stringify(address));
    }

    return (
        <Box
            sx={{
                gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            }}
            width={"100%"}
            alignContent={"center"}
        >
            <Grid container width={"100%"}>
                {addresses?.length > 0 ? (
                    addresses?.map((address, index) => (
                        <Grid item xs key={index} width={"100%"} sm={12} md={6}>
                            <Box
                                key={index}
                                display={"block"}
                                sx={{
                                    mx: 1,
                                    my: 2,
                                    maxWidth: "100%",
                                    border: "1px solid gray",
                                    borderRadius: "10px",
                                    p: 1,
                                }}
                            >
                                <Box width={"100%"} textAlign={"center"} alignItems={"center"}>
                                    <Grid container width={"100%"}>
                                        <Grid item xs width={"100%"} display={"flex"} sm={12} md={6} alignItems={"center"}>
                                            <Typography gutterBottom variant="p" component="div" display={"flex"} alignItems={"center"}>
                                                <Radio
                                                    checked={index === checked}
                                                    onChange={() => {
                                                        handleSelect(index, address);
                                                    }}
                                                    value={index}
                                                    name="radio-buttons"
                                                    inputProps={{ "aria-label": "A" }}
                                                />
                                                {address.city_name}
                                            </Typography>
                                            <Chip
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                sx={{
                                                    width: "auto",
                                                    ml: 1,
                                                    borderRadius: "5px",
                                                    borderColor: "gray"
                                                }}
                                                label={address.type}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            width={"100%"}
                                            sm={12}
                                            md={6}
                                            display={"flex"}
                                            alignItems={"center"}
                                            sx={{ mb: { sm: 2, md: 0 }, float: "right" }}
                                        >

                                            {/* here first we have to integrate map  */}


                                            {/* it's for address section in profile  */}
                                            <Backdrop open={editIndex === index} sx={{ zIndex: 1000 }}>
                                                <Box
                                                    sx={{
                                                        background: theme.palette.background.box,
                                                        width: "1000px",
                                                        borderRadius: "10px",
                                                        padding: 3,
                                                    }}
                                                >
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        marginBottom={2}
                                                    >
                                                        <Typography variant="h6">Update Address</Typography>
                                                        <IconButton onClick={handleEditClose}>
                                                            <CloseRounded />
                                                        </IconButton>
                                                    </Box>
                                                    <Divider />
                                                    {/* Render the UpdateAddress component with the appropriate address */}
                                                    {editIndex === index && (
                                                        <UpdateAddress
                                                            address={address}
                                                            addressUpdate={handleUpdate}
                                                            index={index}
                                                        />
                                                    )}
                                                </Box>
                                            </Backdrop>

                                            <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                                                <IconButton
                                                    aria-label="edit"
                                                    size="small"
                                                    onClick={e => {
                                                        if (isAddressInDrawer) {
                                                            handleEdit(index, address);
                                                        } else {
                                                            handleEditDrawer(address);
                                                        }

                                                        localStorage.setItem(
                                                            "EditableAddress",
                                                            JSON.stringify(address)
                                                        );
                                                    }}
                                                    sx={{
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        mr: 1,
                                                        borderRadius: 2,
                                                        '&:hover': {
                                                            backgroundColor: "green",
                                                        }
                                                    }}
                                                >
                                                    <BorderColorTwoTone fontSize="small" />
                                                </IconButton>

                                                <IconButton
                                                    aria-label="delete"
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: "red",
                                                        color: "white",
                                                        mr: 1,
                                                        borderRadius: 2,
                                                        "&:hover": {
                                                            backgroundColor: "red",
                                                        }
                                                    }}
                                                    onClick={() => handleDelete(index)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </IconButton>
                                            </Box>

                                            <Backdrop open={deleteIndex === index} >
                                                <Box
                                                    sx={{ background: theme.palette.background.box }}
                                                    p={4}
                                                >
                                                    <Typography>{t("delete_address")}</Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleDeleteAddress(address.id)}
                                                    >
                                                        {t("delete")}
                                                    </Button>
                                                    <Button onClick={handleDeleteClose}>{t("close")}</Button>
                                                </Box>
                                            </Backdrop>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography color="text.secondary" variant="body2" p={1}>
                                    {address.address}
                                </Typography>
                                <Typography color="text.secondary" variant="body2" p={1}>
                                    {address.mobile}
                                </Typography>
                            </Box>
                        </Grid>
                    ))
                ) : (
                    <Box width={"100%"}>
                        <Typography py={4} width={"100%"} textAlign={"center"}>{t("no_address")}</Typography>
                    </Box>
                )}
            </Grid>

            <Drawer open={openDrawer}
                anchor="right"
                sx={{
                    display: { xs: "block", sm: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: { md: 580, xs: "100%" },
                    },
                }}>
                <Box >
                    <UpdateMapSide
                        openForm={setFormOpen}
                        closeMap={setOpenDrawer}
                    />
                </Box>
            </Drawer>

            <Drawer open={formOpen} anchor="right"
                sx={{
                    display: { xs: "block", sm: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: { md: 580, xs: "100%" },
                    },
                }}
            >
                <Box >
                    <UpdateDrawer
                        openForm={setFormOpen}
                        closeMap={setOpenDrawer}
                    />
                </Box>
            </Drawer>
        </Box>
    );
};
